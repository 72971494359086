html, body, #root, .App {
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
}

.App {
  display: flex;
  flex-flow: column;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #232323;
  color: #eee;
}

.container {
  flex: 1;
  overflow-y: scroll;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  flex-wrap: wrap;
}

.button {
  background: #454545;
  font-size: 2rem;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 10%;
  aspect-ratio: 1;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: font-size 200ms;
}

.button:hover {
  font-size: 2.5rem;
  cursor: pointer;
}

.clients {
  text-align: center;
  margin-bottom: 10px;
}

.button.button-top-right {
  position: fixed;
  top:0;
  right:0;
  font-size: 1.75rem;
  padding: 0rem;
}
